@font-face {
  font-family: 'Apercu';
  src: local('Apercu'),
       url('./fonts/Apercu_Regular.otf') format('opentype');
}

@font-face {
  font-family: 'Apercu bold';
  src: local('Apercu bold'),
       url('./fonts/Apercu_Bold.otf') format('opentype');
}

html {
  height: 100%;
  background-color: #1D1D15;
}

body {
  height:100%;
  background-color: #1D1D15;
  margin: 0;
  font-family: 'Apercu', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
